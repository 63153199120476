import screenfull from 'screenfull';


class Menu {
     constructor(currentPage,router,common){
        this.currentPage = currentPage;
        this.common = common;
        this.router = router;
    }
    async init(){
        const activity = await this.common.refreshActivity();
        this.audio = new Audio();
        this.audio.autoplay = true;
        this.sound = this.common.getLocal("soundState",true);
        this.music = null;

        this.menuItems = [
            {name:'sound', src: require(`../assets/sound_${this.sound ? "on" : "off"}.png`)},
            {name:'full', src: require('../assets/full.png')},
        ];
        if(activity.lottery.has){
            this.menuItems.push({name:'luckDraw', src: require('../assets/luckdraw.png')});
        }
        if(activity.voting.has){
            this.menuItems.push({name:'ranking', src: require('../assets/ranking.png')});
        }
        if(activity.blessing.has){
            this.menuItems.push({name:'blessing', src: require('../assets/blessing.png')});
        }
    }
    click(item){
        switch(item.name){
            case "sound":
                this.toggleSound(item);
            break;
            case "full":
                screenfull.toggle();
            break;
            default:
                if(item.name != this.currentPage){
                    this.audio.pause();
                    this.router.push({name:item.name});
                }
            break;
        }
    }

    setMusic(music){
        if(music){
            this.music = music;
            if(this.sound){
                this.audio.src = music;
            }
        }
    }

    stopMusic(){
        this.audio.pause();
    }

    toggleSound(item){
        this.sound = !this.sound;
        item.src = require(`../assets/sound_${this.sound ? "on" : "off"}.png`);
        this.common.setLocal("soundState",this.sound);
        if(this.sound){
            if(this.audio.src) {
                this.audio.play();
            }else{
                this.audio.src = this.music;
            }
                
        }else{
            this.audio.pause();
        }
    }
}

export default Menu;